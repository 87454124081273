import custodianModel from './custodianModel/custodianModel.vue';
import customerModel from './customerModel/customerModel.vue';
import rechargeModel from './rechargeModel/rechargeModel.vue';
import ticketModel from './ticketModel/ticketModel.vue';
import rechargeRecordModel from './rechargeRecordModel/rechargeRecordModel.vue';
import couponSelectModel from './couponSelectModel/couponSelectModel.vue'
import departmentSelectModel from './departmentSelectModel/departmentSelectModel.vue'
import scanCodePayModel from './scanCodePayModel/scanCodePayModel.vue'
import selectCardMemberInfo from './selectCardMemberInfo/selectCardMemberInfo.vue'
import memberPreMoneyModel from './memberPreMoneyModel/memberPreMoneyModel.vue'
import invoiceModel from './invoiceModel/invoiceModel.vue'

export {
    /**选择开卡人 */
    custodianModel,
    /**选择客户 */
    customerModel,
    /**充值列表 */
    rechargeModel,
    /**券列表 */
    ticketModel,
    /**充值记录 */
    rechargeRecordModel,
    /**优惠券 */
    couponSelectModel,
    /**选择适用门店 */
    departmentSelectModel,
    /**聚合支付 */
    scanCodePayModel,
    /**选择卡信息 */
    selectCardMemberInfo,
    /**押金支付 */
    memberPreMoneyModel,
    /**开票 */
    invoiceModel,
}